import { logger } from "./logger";

type EventName = 
  'view_onboarding_profile' |
  'view_onboarding_career_and_company' |
  'view_search_keyword_modal' |
  'view_create_comment_modal'  |
  'view_search_book_modal' |
  'view_book_detail' |
  'view_open_library' |
  'view_keyword_library' |
  'view_book_comment_detail' |

  'click_delete_comment_close' |
  'click_delete_comment' | 
  'click_user_library_on_upload_book' |
  'click_user_library_shelf' |
  'click_delete_library_close' |
  'click_delete_library'
  ;

export const track = (
  eventName?: EventName,
  eventData?: Record<string, any>,
): void => {
  if (ENV.MODE !== 'production') {
    logger.info('개발환경에선 이벤트를 추적하지 않아요.', { eventName, eventData })
    return;
  }

  
  if (ENV.PUBLIC_DOMAIN.includes('staging')) {
    logger.info('스테이징 환경에선 이벤트를 추적하지 않아요.', { eventName, eventData })
    return;
  }

  try {
    if (umami) {
      if(!eventName) {
        // void umami?.track();
        return;
      }
      // void umami?.track(eventName, eventData);
    } else {
      logger.error('로드되지 않았어요.', { eventName, eventData });
    }
  } catch (error) {
    logger.error('추적 중 에러가 발생했어요.', { eventName, eventData, error });
  }
  return;
}